@import '../../scss/functions';
@import '../../scss/palette';

.tiktok-widget {
    border: 1px solid $soft-gray;
    border-radius: 12px;

    &__left {
        img {
            width: 100%;
            height: 152px;
            object-fit: cover;
            object-position: center;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
    }

    &__right {
        padding: snif-grid(3);
        text-align: center;

        h3 {
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: $dark-gray;
            margin-bottom: snif-grid(3);
        }
    }

    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 24px 12px 16px;
        color: #ffffff !important;
        text-decoration: none !important;
        border-radius: 6px;
        background-color: #010101;
        cursor: pointer;

        span {
            font-size: 16px;
            line-height: 24px;
        }

        picture {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img {
            width: 24px !important;
            height: 24px !important;
            object-fit: cover;
            object-position: center;
            margin-right: snif-grid(2);
            vertical-align: bottom;
        }
    }

    &__icons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: snif-grid(2);
        gap: 16px;

        a {
            img {
                width: 40px !important;
                height: 40px !important;
                object-fit: cover;
                object-position: center;
            }
        }
    }

    @media #{$min-width-mobile} {
        display: flex;
        max-height: 328px;

        &__left {
            width: 50%;

            img {
                width: 100% !important;
                height: 100% !important;
                border-top-left-radius: 12px;
                border-bottom-left-radius: 12px;
                border-top-right-radius: 0;
            }
        }

        &__right {
            position: relative;
            width: 50%;
            padding: snif-grid(3);
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &.jumbo .tiktok-widget__btn {
            width: 280px;
        }

        &__icons {
            justify-content: flex-start;
            margin-top: snif-grid(1);

            a {
                img {
                    width: 24px !important;
                    height: 24px !important;
                }
            }
        }
    }

    @media screen and (min-width: 1200px) {
        &.jumbo {
            .tiktok-widget__right {
                h3 {
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 40px;
                }
            }

            .tiktok-widget__btn {
                width: 352px;
                padding: 16px;

                span {
                    font-size: 24px;
                    line-height: 32px;
                }

                img {
                    width: 40px;
                    height: 40px;
                }
            }

            .tiktok-widget__icons {
                margin-top: snif-grid(2);

                a {
                    img {
                        width: 32px !important;
                        height: 32px !important;
                    }
                }
            }
        }
    }
}
