@import '../../scss/functions';

.footer {
    font-size: 14px;
    line-height: 32px;
    margin: 0;

    .logo {
        position: relative;
    }

    .paragraph-text {
        margin-bottom: 16px;
    }

    .row {
        a {
            margin: 0 !important;
        }
    }

    a {
        color: #323232;
        margin: 0;

        img {
            width: auto;
        }
    }

    .footer-icons {
        svg,
        img {
            width: 48px;
            height: 48px;
            margin-right: 16px;
            cursor: pointer;
        }
    }

    .content-app-left {
        width: 188px;
        margin-right: snif-grid(2);

        .app-stars {
            margin-right: snif-grid(1);

            svg {
                width: 12px;
                height: 12px;
            }
        }
    }

    .content-app-right {
        width: calc(100% - 188px);

        img {
            height: auto;
            object-fit: contain;
            object-position: center;
        }
    }

    .app-row {
        margin: 0;

        > div {
            margin-bottom: snif-grid(2);

            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            a {
                margin: 0;

                img,
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
