@import 'src/scss/palette';
@import 'src/scss/functions';

.snif-section {
    padding: snif-grid(7) 0;

    @media #{$max-width-mobile} {
        padding: snif-grid(4) 0;
    }

    &.background-green-transparent {
        // background-color: opacify($green-500, 0.15);
        background-color: rgba($green-500, 0.15);
    }
    &.background-green {
        // background-color: opacify($green-500, 0.15);
        background-color: $green-100;
        z-index: 200;
    }
    &.background-snow-white {
        // background-color: opacify($green-500, 0.15);
        background-color: $snow-white;
    }

    .container {
        max-width: 1300px;
    }
}
